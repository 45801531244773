import { Edit, Save } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  ListItemButton,
  ListItemText,
  Button as MuiButton,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Field, Form, Formik } from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import Cart from "../../../components/cart/Cart";
import { CartContext } from "../../../contexts/CartProvider";
import {
  addToCart,
  decrementItemQuantity,
  removeFromCart,
} from "../../../contexts/cartReducer";

import { apiurl } from "../../../config.js";
import { getDate } from "../../../utils/getDate.js";
import BasicForm from "../../components/BasicForm";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { ContactSelector } from "../../../components/ContactSelector.js";
import CartSummary from "../../../components/cart/CartSummary.js";

const axios = require("axios").default;

const Spacer = styled.div`
  flex: 1 1 100%;
`;
const Centered = styled.div`
  text-align: center;
`;
const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);

const steps = ["Ordered", "Accepted", "Picked", "Shipped"];

function DebounceInput(props) {
  const { handleDebounce, setValue, debounceTimeout, ...rest } = props;

  const timerRef = React.useRef();

  const handleChange = (event) => {
    setValue(event.target.value);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      handleDebounce(event.target.value);
    }, debounceTimeout);
  };

  return <TextField {...rest} onChange={handleChange} />;
}

const SearchInput = ({ disabled }) => (
  <Field name="searchValue">
    {({ field }) => (
      <TextField
        {...field}
        label="Search Value"
        fullWidth
        disabled={disabled}
      />
    )}
  </Field>
);

const ContactSelect = (props) => {
  const [search, setSearch] = useState("");
  const [contacts, setContacts] = useState([]);
  const [contact, setContact] = useState({});

  const getProcessedContacts = () => {
    axios.get("/customers?search=" + search).then((response) => {
      setContacts(response.data.results);
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <DebounceInput
        name="search"
        label="Search"
        value={search}
        fullWidth
        debounceTimeout={300}
        setValue={setSearch}
        handleDebounce={getProcessedContacts}
        variant="outlined"
        type="search"
        my={2}
      />
      {contacts?.length > 0 ? (
        <ul>
          {contacts.map((contact) => {
            return (
              <ListItemButton
                component="a"
                key={contact.name}
                onClick={() => {
                  setContact(contact);
                }}
              >
                <ListItemText primary={contact.name} />
              </ListItemButton>
              // <li
              //   onClick={() => {
              //     setContact(contact);
              //   }}
              //   key={contact.name}
              // >
              //   {contact.name}{" "}
              // </li>
            );
          })}{" "}
        </ul>
      ) : (
        <Typography>No Results</Typography>
      )}
    </div>
  );
};

export function User(props) {
  const user = {
    Name: "test",
    Dealer: props.user.dealer,
    Email: props.user.email,
    Phone: props.user.phone,
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Contact
        </Typography>

        <Spacer mb={4} />

        {props.edit ? (
          <BasicForm inputs={user} submit={props.submit}></BasicForm>
        ) : (
          <Centered>
            <Typography>{props.user.first_name}</Typography>
            <Typography>{props.user.dealer}</Typography>
            <Typography>{props.user?.email}</Typography>
            <Typography>{props.user?.phone}</Typography>
          </Centered>
        )}
      </CardContent>
    </Card>
  );
}

const UserSelect = (props) => {
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  useEffect(() => {}, [user]);
  const getProcessedUsers = () => {
    axios.get("/users?search=" + search).then((response) => {
      setUsers(response.data.results);
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <DebounceInput
        name="search"
        label="Search"
        value={search}
        fullWidth
        debounceTimeout={300}
        setValue={setSearch}
        handleDebounce={getProcessedUsers}
        variant="outlined"
        type="search"
        my={2}
      />
      {users?.length > 0 ? (
        <ul>
          {users.map((user, index) => {
            return (
              <ListItemButton
                component="a"
                key={index}
                onClick={() => {
                  setUser(user);
                }}
              >
                <ListItemText
                  primary={user.first_name + " " + user.last_name}
                />
                <ListItemText primary={user.dealer} />
              </ListItemButton>
              // <li
              //   onClick={() => {
              //     setUser(user);
              //   }}
              //   key={user.name}
              // >
              //   {user.name}{" "}
              // </li>
            );
          })}{" "}
        </ul>
      ) : (
        <Typography>No Results</Typography>
      )}
      <User user={user} edit={true} submit={() => {}}></User>
    </div>
  );
};

export function Quote(props) {
  const { state } = useLocation();
  const { cart, dispatch } = useContext(CartContext);
  const timerRef = React.useRef();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const removeFromCartHandler = (itemToRemove) =>
    dispatch(removeFromCart(itemToRemove));
  const addToCartHandler = (product) => {
    dispatch(addToCart(product));
  };
  const decrementFromCartHandler = (product) => {
    dispatch(decrementItemQuantity(product));
  };

  // Customer Address book variables
  const [selectedContact, setSelectedContact] = React.useState(
    state?.customer ? state.customer : null
  );

  const [contactAction, setContactAction] = React.useState(0);
  // 0 = Save contact information for this quote only
  // 1 = Update existing contact information
  // 2 = Save contact information as a new contact.

  const handleContactAction = (event, newSelection) => {
    setContactAction(newSelection);
  };

  const [initialValues, setInitialValues] = React.useState({
    po_number: "",
    contact_name: state?.customer ? state.customer.name : "",
    contact_phone: state?.customer ? state.customer.phone : "",
    contact_email: state?.customer ? state.customer.email : "",
    company_name: state?.customer ? state.customer.company_name : "",
    order_confirmation: null,
    billing_contact_name: state?.customer
      ? state.customer.billing_contact_name
      : "",
    billing_street_address: state?.customer
      ? state.customer.billing_street_address
      : "",
    billing_street_address2: state?.customer
      ? state.customer.billing_street_address2
      : "",
    billing_city: state?.customer ? state.customer.billing_city : "",
    billing_state: state?.customer ? state.customer.billing_state : "",
    billing_zip: state?.customer ? state.customer.billing_zip : "",
    billing_phone: state?.customer ? state.customer.billing_phone : "",
    shipping_contact_name: state?.customer
      ? state.customer.shipping_contact_name
      : "",
    shipping_street_address: state?.customer
      ? state.customer.shipping_street_address
      : "",
    shipping_street_address2: state?.customer
      ? state.customer.shipping_street_address2
      : "",
    shipping_city: state?.customer ? state.customer.shipping_city : "",
    shipping_state: state?.customer ? state.customer.shipping_state : "",
    shipping_zip: state?.customer ? state.customer.shipping_zip : "",
    shipping_phone: state?.customer ? state.customer.shipping_phone : "",
    comments: "",
    stakeholders: [],
    discount: "0.00",
    shipping_cost: "",
    cart_items: state?.cart_items || [],
  });

  const [cartItems, setCartItems] = React.useState(
    props.items ? props.items : []
  );

  function handleContactSelection(contact) {
    if (contact) {
      setSelectedContact(contact);
      setContactAction(0);
      let newContact = {
        contact_name: contact.name,
        contact_email: contact.email,
        contact_phone: contact.phone,
        company_name: contact.company_name,

        billing_contact_name: contact.billing_contact_name,
        billing_street_address: contact.billing_street_address,
        billing_street_address2: contact.billing_street_address2,
        billing_city: contact.billing_city,
        billing_state: contact.billing_state,
        billing_zip: contact.billing_zip,
        billing_phone: contact.billing_phone,
        shipping_contact_name: contact.shipping_contact_name,
        shipping_street_address: contact.shipping_street_address,
        shipping_street_address2: contact.shipping_street_address2,
        shipping_city: contact.shipping_city,
        shipping_state: contact.shipping_state,
        shipping_zip: contact.shipping_zip,
        shipping_phone: contact.shipping_phone,
      };

      setInitialValues((prev) => {
        return { ...prev, ...newContact };
      });
    }
  }

  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    // Simulate an API call to fetch initial values
    // Replace this with your actual API call

    axios.get("/quotes/" + id + "/").then((resp) => {
      // const items = resp.data.cart_items.map((item) => {
      //   return {
      //     product: { ...item },
      //     quantity: item.quantity,
      //     discount: item.discount || 0,
      //   };
      // });

      // const quoteItems = cart.map((item) => {
      //   return {
      //     product: item.id,
      //     quantity: item.quantity,
      //     discount: item.discount || 0,
      //   };
      // });
      // resp.data.cart_items = quoteItems;
      setInitialValues(resp.data);
      setLoading(false);
    });
    // setTimeout(() => {
    //   setInitialValues({
    //     id: "",
    //     contact_name: "John Doe",
    //     contact_phone: "1231231234",
    //     company_name: "Acme Inc",
    //     billing_contact_name: "Bob Smith",
    //     billing_street_address: "123 Bob Ln",
    //     billing_street_address2: "Suite 1",
    //     billing_city: "Portland",
    //     billing_state: "OR",
    //     billing_zip: "97123",
    //     billing_phone: "1231231234",
    //     shipping_contact_name: "John Doe",
    //     shipping_street_address: "1 Johnny Way",
    //     shipping_street_address2: "",
    //     shipping_city: "Seattle",
    //     shipping_state: "WA",
    //     shipping_zip: "97321",
    //     shipping_phone: "1231231234",
    //     cart_items: quoteItems,
    //   });
    //   setLoading(false);
    // }, 1000);
  }, [id]);
  const navigate = useNavigate();

  useEffect(() => {}, [initialValues]);

  const handleSubmit = async (values) => {
    // Handle form submission logic here

    const resp = await axios.post("/quotes/" + id + "/convert_to_order/", {
      po_number: values.po_number ? values.po_number : "12345",
    });

    if (resp.data.order_created) {
      navigate("/order/" + resp.data.order_created + "/");
    }
  };

  const handleDuplicate = async (values) => {
    // Handle form submission logic here

    const keys = [
      "shipping_cost",
      "discount",
      "contact_name",
      "contact_phone",
      "contact_email",
      "company_name",
      "billing_contact_name",
      "billing_street_address",
      "billing_street_address2",
      "billing_city",
      "billing_state",
      "billing_zip",
      "billing_phone",
      "shipping_contact_name",
      "shipping_street_address",
      "shipping_street_address2",
      "shipping_city",
      "shipping_state",
      "shipping_zip",
      "shipping_phone",
      "comments",
    ];

    const cartKeys = ["product", "quantity", "discount"];
    const res = keys.reduce(
      (a, c) => (values[c] ? (a[c] = values[c]) : c, a),
      {}
    );

    res.new_cart_items = values.cart_items.map((item) => {
      return {
        product: item.product.id ? item.product.id : item.product,
        quantity: item.quantity,
        discount: item.discount || 0,
      };
    });

    res.valid_until = res.valid_until
      ? dayjs(res.valid_until).format("YYYY-MM-DD")
      : dayjs().add(30, "day").format("YYYY-MM-DD");

    const resp = await axios.post("/quotes/", res);

    if (resp.data.id) {
      navigate("/quote/" + resp.data.id);
    }
  };

  const handleExport = async (values) => {
    let url = apiurl + "/quotes/" + id + "/download_pdf";
    var pdfredirect = window.open(url, "_blank");
    pdfredirect.focus();
  };

  const handleDelete = async (values) => {
    // Handle form submission logic here

    const resp = await axios.delete("/quotes/" + id);
    navigate("/quotes/quoted");
  };

  const MyInput = ({ field, form, ...props }) => {
    return <TextField {...field} {...props} />;
  };

  const InputCard = (props) => {
    return (
      <div key={props.index}>
        {props.edit ? (
          // <Field render={({ field, form }) => (
          //   <TextField {...props.textField} fullWidth variant="outlined" my={2} />
          // )}></Field>
          // <Field
          //   validateOnBlur
          //   validateOnChange
          //   name="firstName"
          //   render={({ field, form }) => (
          <TextField
            {...props.textField}
            fullWidth
            variant="outlined"
            my={2}
            value={props.form.values[props.textField.id]}
            // error={Boolean(form.errors.firstName && form.touched.firstName)}
            onChange={props.form.handleChange}
            onBlur={props.form.handleBlur}
            // helperText={
            //   props.form.errors.firstName &&
            //   props.form.touched.firstName &&
            //   String(props.form.errors.firstName)
            // }
          />
        ) : (
          //   )}
          // />
          <Typography my={2} gutterBottom key={props.index}>
            {props.initialValue}
          </Typography>
        )}
      </div>
    );
  };

  const handleSave = async (values) => {
    values.new_cart_items = values.cart_items.map((item) => {
      return {
        product: item.product.id ? item.product.id : item.product,
        quantity: item.quantity,
        discount: item.discount || 0,
      };
    });

    values.valid_until = dayjs(values.valid_until).format("YYYY-MM-DD");

    const resp = await axios.put("/quotes/" + id + "/", values);

    setInitialValues(resp.data);
    setEdit(!edit);
  };

  // prettier-ignore
  const quoteDetails = [
    {"textField": {id: "quote_number", name: "quote_number", label: "Quote Number"}, label: "Quote Number", initialValue: initialValues.quote_number},
    {"textField": {id: "date_created", name: "date_created", label: "Date_created"}, label: "Date Created", initialValue: initialValues.date_created},
    {"textField": {id: "dealer", name: "dealer", label: "dealer"}, label: "Dealer", initialValue: initialValues.dealer},
  ];

  // prettier-ignore
  const shippingDetails = [
    {"textField": {id: "shipping_contact_name", name: "shipping_contact_name", label: "Shipping Contact Name"}, label: "Shipping Contact Name", initialValue: initialValues.shipping_contact_name},
    {"textField": {id: "shipping_street_address", name: "shipping_street_address", label: "shipping_street_address"}, label: "shipping_street_address", initialValue: initialValues.shipping_street_address},
    {"textField": {id: "shipping_street_address2", name: "shipping_street_address2", label: "shipping_street_address2"}, label: "shipping_street_address2", initialValue: initialValues.shipping_street_address2},
    {"textField": {id: "shipping_city", name: "shipping_city", label: "shipping_city"}, label: "shipping_city", initialValue: initialValues.shipping_city},
    {"textField": {id: "shipping_state", name: "shipping_state", label: "shipping_state"}, label: "shipping_state", initialValue: initialValues.shipping_state},
    {"textField": {id: "shipping_zip", name: "shipping_zip", label: "shipping_zip"}, label: "shipping_zip", initialValue: initialValues.shipping_zip},
    {"textField": {id: "shipping_phone", name: "shipping_phone", label: "shipping_phone"}, label: "shipping_phone", initialValue: initialValues.shipping_phone},
    ];

  // prettier-ignore
  const billingDetails = [
    {"textField": {id: "billing_contact_name", name: "billing_contact_name", label: "Billing Contact Name"}, label: "Billing Contact Name", initialValue: initialValues.billing_contact_name},
    {"textField": {id: "billing_street_address", name: "billing_street_address", label: "billing_street_address"}, label: "billing_street_address", initialValue: initialValues.billing_street_address},
    {"textField": {id: "billing_street_address2", name: "billing_street_address2", label: "billing_street_address2"}, label: "billing_street_address2", initialValue: initialValues.billing_street_address2},
    {"textField": {id: "billing_city", name: "billing_city", label: "billing_city"}, label: "billing_city", initialValue: initialValues.billing_city},
    {"textField": {id: "billing_state", name: "billing_state", label: "billing_state"}, label: "billing_state", initialValue: initialValues.billing_state},
    {"textField": {id: "billing_zip", name: "billing_zip", label: "billing_zip"}, label: "billing_zip", initialValue: initialValues.billing_zip},
    {"textField": {id: "billing_phone", name: "billing_phone", label: "billing_phone"}, label: "billing_phone", initialValue: initialValues.billing_phone},
  ];

  // const Basic = () => (
  //   <div>
  //     <h1>Sign Up</h1>
  //     <Formik
  //       initialValues={{
  //         firstName: "",
  //         lastName: "",
  //         email: "",
  //       }}
  //       onSubmit={async (values) => {
  //         await new Promise((r) => setTimeout(r, 500));
  //         alert(JSON.stringify(values, null, 2));
  //       }}
  //     >
  //       <Form>
  //         <label htmlFor="firstName">First Name</label>
  //         <Field id="firstName" name="firstName" placeholder="Jane" />

  //         <label htmlFor="lastName">Last Name</label>
  //         <Field id="lastName" name="lastName" placeholder="Doe" />

  //         <label htmlFor="email">Email</label>
  //         <Field
  //           id="email"
  //           name="email"
  //           placeholder="jane@acme.com"
  //           type="email"
  //         />
  //         <button type="submit">Submit</button>
  //       </Form>
  //     </Formik>
  //   </div>
  // );
  useEffect(() => {
    setLoading(true);
    // setOrders([]);
    var url = "/customers/" + "?search=" + searchQuery;
    // if (props.status) {
    //   url = "/orders/?status=" + props.status;
    // }
    axios
      .get(url)
      .then((response) => {
        setSearchResults(response.data.results);
      })
      .then(() => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [searchQuery]);
  // return <Basic></Basic>;
  const mapContactToQuote = (contact) => {
    // TODO delete name
    const result = contact;
    result.contact_email = contact?.email;

    return result;
  };

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const openConvertedOrder = () => {
    navigate("/order/" + initialValues.order_created + "/");
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: "form",
            onSubmit: async (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
              const po_number = formJson.po_number;
              const resp = await axios.post(
                "/quotes/" + id + "/convert_to_order/",
                { po_number: po_number ? po_number : "12345" }
              );

              if (resp.data.order_created) {
                navigate("/order/" + resp.data.order_created + "/");
              }
              handleClose();
            },
          }}
        >
          <DialogTitle>Create Order</DialogTitle>
          <DialogContent>
            <DialogContentText>Enter the PO # for the Order.</DialogContentText>
            <TextField
              autoFocus
              required
              sx={{ marginTop: 5 }}
              margin="dense"
              id="name"
              name="po_number"
              defaultValue={initialValues.quote_number}
              label="PO Number"
              fullWidth
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Create</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
        >
          <DialogTitle>Delete Order</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this quote?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDeleteDialog(false)}>Cancel</Button>
            <Button variant="contained" color="error" onClick={handleDelete}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={Yup.object().shape({
            company_name: Yup.string()
              .max(250)
              .required("Contact name is required"),
            discount: Yup.number()
              .min(0.0, "Discount must be 0-100")
              .max(100.0, "Discount must be less than 100"),
            contact_name: Yup.string()
              .max(250)
              .required("Contact name is required"),
            cart_items: Yup.array()
              .min(1, "At least one item is required")
              .required("At least one item is required"),
            contact_email: Yup.string().email("Must be a valid email").max(250),
            shipping_cost: Yup.number().required("Shipping Cost is Required"),
          })}
          onSubmit={async (values) => {
            await handleSave(values);
            // await new Promise((r) => setTimeout(r, 500));
            // alert(JSON.stringify(values, null, 2));
          }}
        >
          {(form) => (
            <Form>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="h1" gutterBottom display="inline">
                      Quote# {initialValues.quote_number}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column-reverse",
                    }}
                  >
                    {edit ? (
                      <div>
                        <IconButton
                          aria-label="details"
                          size="large"
                          // onClick={() => {
                          //   // setEdit(!edit);
                          //   handleSave(values);
                          // }}
                          type="submit"
                        >
                          <Save />
                        </IconButton>
                      </div>
                    ) : (
                      // <Button type="submit">
                      //   <Save />
                      // </Button>
                      <IconButton
                        aria-label="details"
                        size="large"
                        onClick={() => {
                          setEdit(!edit);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    )}
                  </div>
                </div>
                {edit ? (
                  <div style={{ width: "100%" }}>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "white",
                        padding: 0,
                        marginTop: 10,
                        // marginLeft: 40,
                        alignItems: "end",
                        // borderRadius: 4,
                        paddingBottom: 5,
                      }}
                      justifyContent="space-between"
                      container
                      // spacing={10}
                    >
                      <ContactSelector
                        selectedContact={selectedContact}
                        handleContactSelection={handleContactSelection}
                      />
                    </Grid>
                  </div>
                ) : (
                  <></>
                )}

                <div>
                  <Card style={{ marginBottom: 12 }}>
                    <CardContent>
                      {edit ? (
                        <TextField
                          id="company_name"
                          name="company_name"
                          label="Company Name"
                          fullWidth
                          variant="outlined"
                          my={4}
                          value={form.values.company_name}
                          error={Boolean(
                            form.errors.company_name &&
                              form.touched.company_name
                          )}
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                        />
                      ) : (
                        <Typography variant="h4">
                          {initialValues.company_name}
                        </Typography>
                      )}
                      {edit ? (
                        <Grid container spacing={6}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="contact_name"
                              name="contact_name"
                              label="Contact Name"
                              fullWidth
                              variant="outlined"
                              value={form.values.contact_name}
                              error={Boolean(
                                form.errors.contact_name &&
                                  form.touched.contact_name
                              )}
                              helperText={
                                form.touched.contact_name &&
                                form.errors.contact_name
                              }
                              onChange={form.handleChange}
                              onBlur={form.handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MuiPhoneNumber
                              name="contact_phone"
                              label="Phone Number"
                              value={form.values.contact_phone}
                              data-cy="number"
                              defaultCountry={"us"}
                              onChange={(e) =>
                                form.setFieldValue("contact_phone", e)
                              }
                              onBlur={form.handleBlur}
                              // helperText={touched.phone && errors.phone}
                              // error={Boolean(touched.phone && errors.phone)}
                              fullWidth
                              type="tel"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <div style={{ display: "flex" }}>
                          <Typography
                            style={{ marginTop: 10, marginRight: 10 }}
                          >
                            {initialValues.contact_name}
                          </Typography>
                          <Typography style={{ marginTop: 10 }}>
                            {initialValues.contact_phone}
                          </Typography>
                        </div>
                      )}
                      {edit ? (
                        <TextField
                          id="contact_email"
                          name="contact_email"
                          label="Email"
                          fullWidth
                          variant="outlined"
                          value={form.values.contact_email}
                          my={4}
                          // error={Boolean(form.errors.firstName && form.touched.firstName)}
                          helperText={
                            form.touched.contact_email
                              ? form.errors.contact_email
                              : ""
                          }
                          error={
                            form.touched.contact_email &&
                            Boolean(form.errors.contact_email)
                          }
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                        />
                      ) : (
                        <Typography style={{ marginTop: 10 }}>
                          {initialValues.contact_email}
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                  {!edit && (
                    <Card style={{ marginBottom: 12 }}>
                      <CardContent>
                        <Typography
                          variant="h6"
                          my={2}
                          gutterBottom
                          key={props.index}
                        >
                          Quote: {initialValues.quote_number}
                        </Typography>
                        <Typography my={2} gutterBottom key={props.index}>
                          {getDate(initialValues.date_created)}
                        </Typography>
                      </CardContent>
                    </Card>
                  )}
                  {edit ? (
                    <Grid container spacing={6} marginBottom={6}>
                      <Grid item xs={12} sm={6}>
                        <Card>
                          <CardContent>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                              }}
                            >
                              <Typography variant="h6">Bill To</Typography>
                              <Button
                                inputProps={{ "aria-label": "controlled" }}
                                onClick={() => {
                                  const newFormValues = form.values;
                                  newFormValues.shipping_contact_name =
                                    newFormValues.billing_contact_name;
                                  newFormValues.shipping_street_address =
                                    newFormValues.billing_street_address;
                                  newFormValues.shipping_street_address2 =
                                    newFormValues.billing_street_address2;
                                  newFormValues.shipping_city =
                                    newFormValues.billing_city;
                                  newFormValues.shipping_state =
                                    newFormValues.billing_state;
                                  newFormValues.shipping_zip =
                                    newFormValues.billing_zip;
                                  newFormValues.shipping_phone =
                                    newFormValues.billing_phone;
                                  form.setValues({ ...newFormValues });
                                }}
                              >
                                Copy to Shipping
                              </Button>
                            </div>
                            <Grid
                              container
                              spacing={6}
                              style={{ marginTop: 5 }}
                            >
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  name="billing_contact_name"
                                  label="Billing Contact Name"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.billing_contact_name}
                                  // error={Boolean(form.errors.firstName && form.touched.firstName)}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <MuiPhoneNumber
                                  name="billing_phone"
                                  label="Billing Phone"
                                  value={form.values.billing_phone}
                                  data-cy="number"
                                  defaultCountry={"us"}
                                  onChange={(e) =>
                                    form.setFieldValue("billing_phone", e)
                                  }
                                  onBlur={form.handleBlur}
                                  // helperText={touched.phone && errors.phone}
                                  // error={Boolean(touched.phone && errors.phone)}
                                  fullWidth
                                  type="tel"
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  name="billing_street_address"
                                  label="Billing Street Address"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.billing_street_address}
                                  // error={Boolean(form.errors.firstName && form.touched.firstName)}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  name="billing_street_address2"
                                  label="Billing Street Address2"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.billing_street_address2}
                                  // error={Boolean(form.errors.firstName && form.touched.firstName)}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <TextField
                                  name="billing_city"
                                  label="City"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.billing_city}
                                  // error={Boolean(form.errors.firstName && form.touched.firstName)}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  name="billing_state"
                                  label="State"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.billing_state}
                                  // error={Boolean(form.errors.firstName && form.touched.firstName)}
                                  inputProps={{ maxLength: 2 }}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  name="billing_zip"
                                  label="Zip"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.billing_zip}
                                  // error={Boolean(form.errors.firstName && form.touched.firstName)}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Card>
                          <CardContent>
                            <Typography variant="h6">Ship To</Typography>
                            <Grid
                              container
                              spacing={6}
                              style={{ marginTop: 5 }}
                            >
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  name="shipping_contact_name"
                                  label="Shipping Contact Name"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.shipping_contact_name}
                                  // error={Boolean(form.errors.firstName && form.touched.firstName)}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <MuiPhoneNumber
                                  name="shipping_phone"
                                  label="Shipping Phone Number"
                                  value={form.values.shipping_phone}
                                  data-cy="number"
                                  defaultCountry={"us"}
                                  onChange={(e) =>
                                    form.setFieldValue("shipping_phone", e)
                                  }
                                  onBlur={form.handleBlur}
                                  // helperText={touched.phone && errors.phone}
                                  // error={Boolean(touched.phone && errors.phone)}
                                  fullWidth
                                  type="tel"
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  name="shipping_street_address"
                                  label="Shipping Street Address"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.shipping_street_address}
                                  // error={Boolean(form.errors.firstName && form.touched.firstName)}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  name="shipping_street_address2"
                                  label="Shipping Street Address2"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.shipping_street_address2}
                                  // error={Boolean(form.errors.firstName && form.touched.firstName)}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <TextField
                                  name="shipping_city"
                                  label="City"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.shipping_city}
                                  // error={Boolean(form.errors.firstName && form.touched.firstName)}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <TextField
                                  name="shipping_state"
                                  label="State"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.shipping_state}
                                  // error={Boolean(form.errors.firstName && form.touched.firstName)}
                                  inputProps={{ maxLength: 2 }}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  name="shipping_zip"
                                  label="Zip"
                                  fullWidth
                                  variant="outlined"
                                  value={form.values.shipping_zip}
                                  // error={Boolean(form.errors.firstName && form.touched.firstName)}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={6} marginBottom={6}>
                      <Grid item xs={12} sm={6}>
                        <Card>
                          <CardContent>
                            <Typography variant="h6">Bill To</Typography>
                            <Grid
                              container
                              spacing={6}
                              style={{ marginTop: 5 }}
                            >
                              <Grid item>
                                <div>
                                  <Typography>
                                    {initialValues.billing_contact_name}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography>
                                    {initialValues.billing_phone}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography>
                                    {initialValues.billing_street_address}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography>
                                    {initialValues.billing_street_address2}
                                  </Typography>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div style={{ marginRight: 5 }}>
                                    <Typography>
                                      {initialValues.billing_city},
                                    </Typography>
                                  </div>
                                  <Typography>
                                    {initialValues.billing_state}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography>
                                    {initialValues.billing_zip}
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Card>
                          <CardContent>
                            <Typography variant="h6">Ship To</Typography>
                            <Grid
                              container
                              spacing={6}
                              style={{ marginTop: 5 }}
                            >
                              <Grid item>
                                <div>
                                  <Typography>
                                    {initialValues.shipping_contact_name}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography>
                                    {initialValues.shipping_phone}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography>
                                    {initialValues.shipping_street_address}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography>
                                    {initialValues.shipping_street_address2}
                                  </Typography>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div style={{ marginRight: 5 }}>
                                    <Typography>
                                      {initialValues.shipping_city},
                                    </Typography>
                                  </div>
                                  <Typography>
                                    {initialValues.shipping_state}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography>
                                    {initialValues.shipping_zip}
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12} my={2}>
                    <Card mb={6}>
                      <CardContent>
                        <Typography variant="h6" gutterBottom>
                          Valid Until
                        </Typography>

                        <Spacer mb={4} />
                        {edit ? (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              id="valid_until"
                              label="Valid Until"
                              name="valid_until"
                              defaultValue={dayjs(initialValues.valid_until)}
                              value={form.values.date}
                              onChange={(value) =>
                                form.setFieldValue("valid_until", value, true)
                              }
                            />
                          </LocalizationProvider>
                        ) : (
                          <Typography>{initialValues.valid_until}</Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={12} my={2}>
                    <Card mb={6}>
                      <CardContent>
                        <Typography variant="h6" gutterBottom>
                          Comments
                        </Typography>

                        <Spacer mb={4} />
                        {edit ? (
                          <TextField
                            id="comments"
                            label="comments"
                            name="comments"
                            multiline
                            fullWidth
                            rows={4}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.comments}
                            inputProps={{ maxLength: 250 }}
                          />
                        ) : (
                          <Typography>
                            {initialValues.comments
                              ? initialValues.comments
                              : "No Comments"}
                          </Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>

                  <Cart
                    cartItems={form.values.cart_items}
                    setCartItems={(update) => {
                      const prev = form.values;
                      if (typeof update === "function") {
                        prev.cart_items = update(prev.cart_items);

                        form.setFieldValue("cart_items", prev.cart_items);
                      } else {
                        form.setFieldValue("cart_items", update);
                      }
                    }}
                    edit={edit}
                  />

                  {edit ? (
                    <Card my={1}>
                      <CardContent>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <TextField
                            id="discount"
                            name="discount"
                            label="% Discount"
                            fullWidth
                            variant="outlined"
                            required={true}
                            my={2}
                            value={form.values.discount}
                            // error={Boolean(form.errors.firstName && form.touched.firstName)}
                            error={Boolean(
                              form.errors.discount && form.touched.discount
                            )}
                            helperText={
                              form.touched.discount && form.errors.discount
                            }
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            style={{ maxWidth: 200, alignSelf: "flex-end" }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <TextField
                            id="shipping_cost"
                            name="shipping_cost"
                            label="Shipping Cost"
                            required={true}
                            fullWidth
                            variant="outlined"
                            my={2}
                            value={form.values.shipping_cost}
                            // error={Boolean(form.errors.firstName && form.touched.firstName)}
                            error={Boolean(
                              form.errors.shipping_cost &&
                                form.touched.shipping_cost
                            )}
                            helperText={
                              form.touched.shipping_cost &&
                              form.errors.shipping_cost
                            }
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            style={{ maxWidth: 200, alignSelf: "flex-end" }}
                          />
                        </div>
                      </CardContent>
                    </Card>
                  ) : (
                    <></>
                  )}

                  <Card my={1}>
                    <CardContent>
                      <CartSummary
                        cartItems={form.values.cart_items}
                        discount={form.values.discount}
                        shipping_cost={form.values.shipping_cost}
                      />
                    </CardContent>
                  </Card>

                  {/* {error && <Alert severity="error">{error}</Alert>} */}
                  {edit ? (
                    <div
                      style={{
                        marginTop: 20,
                        display: "flex",
                        justifyContent: "space-between",
                        maxWidth: 800,
                        alignSelf: "center",
                        justifySelf: "center",
                      }}
                    >
                      <Button variant="contained" type="submit">
                        Save Edits
                      </Button>
                    </div>
                  ) : (
                    <div
                      style={{
                        marginTop: 20,
                        display: "flex",
                        justifyContent: "space-between",
                        maxWidth: 800,
                        alignSelf: "center",
                        justifySelf: "center",
                      }}
                    >
                      {initialValues.order_created ? (
                        <Button
                          variant="contained"
                          color="success"
                          onClick={openConvertedOrder}
                        >
                          View Order
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleClickOpen}
                        >
                          Convert to Order
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleExport(form.values)}
                      >
                        Export PDF
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDuplicate(form.values)}
                      >
                        Create Duplicate
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => setShowDeleteDialog(true)}
                      >
                        Delete
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Quote;
